$colors-list: (
  neutral: (
    1: #ffffff,
    2: #fafafa,
    3: #f0f0f0,
    4: #e6e6e6,
    5: #ededed,
    6: #bdbdbd,
    7: #9e9e9e,
    8: #6d6d6d,
    9: #4f4f4f,
    10: #171717,
  ),
);

@each $baseColor, $variants in $colors-list {
  @each $variantName, $value in $variants {
    .bg-#{$baseColor}-#{$variantName} {
      background-color: #{$value} !important;
    }
    .border-color-#{$baseColor}-#{$variantName} {
      border-color: #{$value};
    }
    .svg-path-fill-#{$baseColor}-#{$variantName} {
      path {
        fill: #{$value};
      }
    }
    .#{$baseColor}-#{$variantName} {
      color: #{$value} !important;
    }
  }
}
