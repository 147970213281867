.rounded-2 {
  border-radius: 2px;
}

.rounded-4 {
  border-radius: 4px;
}

.rounded-8 {
  border-radius: 8px;
}

.rounded-16 {
  border-radius: 16px;
}

.rounded {
  border-radius: 50% !important;
}

.border-b {
  border-bottom: 1px solid #f0f0f0;
}

.bordered {
  border: 1px solid #f0f0f0;
}
